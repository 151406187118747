footer {
    background-color: #000000;
    color: @color-white;

    .footer-message {
        padding: 30px 0;

        h2, p{
            text-align: center;
        }

        h2 {
            text-transform: uppercase;
        }

        p {
            line-height: 1.2;
            font-size: .9em;
            margin: .5em 0;
            -webkit-font-smoothing: antialiased;
            color: @color-gray;
        }
    }

    .vagebond-logo {
        opacity: .4;
        h1 {
            margin: 0 0 1em 0;
            width: 130px;
            height: 50px;
            background: url(../../images/logo-vagebond.svg) 50% 50% no-repeat;
            background-size: contain;

            span {
                .text-hide();
            }
        }
    }
}