.button {
    -webkit-font-smoothing: antialiased;
    padding: 10px 20px;
    border: 2px solid @color-black;
    color: @color-black;
    background-color: @color-black;
    color: @color-white;
    border-radius: 25px;
    font-weight: 100;

    &:hover {
        background-color: @color-blue;
        color: @color-white;
        border-color: @color-blue;
    }
}