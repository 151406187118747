.header-top {
    transition: all .3s;
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;

    .logo {
        color: @color-white;

        h1 {
            transition: width .3s;
            margin: 0;
            width: 120px;
            height: 70px;
            background: url(../../images/logo.svg) 50% 50% no-repeat;
            background-size: contain;

            span {
                .text-hide();
            }
        }
    }

    .purchase {
        transition: all .3s;
        margin: 15px 0;
        float: right;
    }

    &.down {
        background: rgba(0, 0, 0, .75);

        .logo h1 {
            background: url(../../images/logo-white.svg) 50% 50% no-repeat;
            background-size: contain;
            height: 60px;
            width: 100px;
        }

        .purchase {
            margin: 11px 0;
            border-color: @color-white;
            background-color: rgba(0, 0, 0, 0);
            font-size: .8em;

            &:hover {
                background-color: @color-white;
                color: @color-black;
            }
        }
    }
}