a {
    text-decoration: none;
    transition: color .3s, background-color .3s, border-color .3s;
    display: inline-block;
}

h1 {
    font-size: 3.2em;

    @media @mq-phone {
        font-size: 2.9em;
    }
}