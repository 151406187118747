.message {
    text-align: center;
    margin-top: 7em;

    h1, h3 {
        letter-spacing: -1px;
    }

    h1 {
        color: @color-black;
        margin: 1em 0.5em 0;
        text-transform: uppercase;
    }

    h3 {
        color: @color-gray;
        font-weight: 100;
        -webkit-font-smoothing: antialiased;
    }
}
