.items {
    list-style: none;
    margin: 0;
    padding: 0;

    li a {
        display: block;
        overflow: hidden;
        box-shadow: 0 35px 55px rgba(0, 0, 0, .08), 0 25px 60px rgba(0, 0, 0, .08);
        border-radius: 3px;
        background: white;
        box-sizing: border-box;
        margin-bottom: 100px;
        position: relative;

        @media @mq-medium {
            margin-bottom: 80px;
        }

        @media @mq-tablet {
            margin-bottom: 60px;
        }

        @media @mq-phone {
            margin-bottom: 50px;
        }

        @media @mq-phone-landscape {
            margin-bottom: 50px;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(68, 68, 68, .7);
            opacity: 0;
            transition: opacity .3s;

            h3 {
                font-size: .9em;
                display: inline-block;
                padding: 10px 20px;
                border-radius: 20px;
                margin: 0;
                transition: transform .3s;
                position: relative;
                text-align: center;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 10%);
                background-color: black;
                color: @color-white;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

        &:hover {
            .overlay {
                opacity: 1;

                h3 {
                    transform: translate(-50%, -50%);
                }
            }
        }

        img {
            display: block;
            width: 100%;
        }
    }
}