@import 'common/variables';
@import 'common/common';
@import 'common/mixins/text-hide';
@import 'common/typography';

html {
    color: white;
    background: url(../../images/grass.jpg) 50% 50% fixed;
    background-size: cover;
    font-family: sans-serif;
}

body {
    font: 100%/1.07 @font-sans;
    margin: 0;
}

@import 'elements/header-top';
@import 'elements/items';
@import 'elements/wrappers';
@import 'elements/message';
@import 'elements/button';
@import 'elements/footer';
@import 'elements/form';