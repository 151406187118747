img {
  border: 0;
}
a {
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s;
  display: inline-block;
}
h1 {
  font-size: 3.2em;
}
@media only screen and (max-width: 700px) {
  h1 {
    font-size: 2.9em;
  }
}
html {
  color: white;
  background: url(../../images/grass.jpg) 50% 50% fixed;
  background-size: cover;
  font-family: sans-serif;
}
body {
  font: 100%/1.07 'Montserrat', sans-serif;
  margin: 0;
}
.header-top {
  transition: all .3s;
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
}
.header-top .logo {
  color: #ffffff;
}
.header-top .logo h1 {
  transition: width .3s;
  margin: 0;
  width: 120px;
  height: 70px;
  background: url(../../images/logo.svg) 50% 50% no-repeat;
  background-size: contain;
}
.header-top .logo h1 span {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.header-top .purchase {
  transition: all .3s;
  margin: 15px 0;
  float: right;
}
.header-top.down {
  background: rgba(0, 0, 0, 0.75);
}
.header-top.down .logo h1 {
  background: url(../../images/logo-white.svg) 50% 50% no-repeat;
  background-size: contain;
  height: 60px;
  width: 100px;
}
.header-top.down .purchase {
  margin: 11px 0;
  border-color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  font-size: .8em;
}
.header-top.down .purchase:hover {
  background-color: #ffffff;
  color: #444;
}
.items {
  list-style: none;
  margin: 0;
  padding: 0;
}
.items li a {
  display: block;
  overflow: hidden;
  box-shadow: 0 35px 55px rgba(0, 0, 0, 0.08), 0 25px 60px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  box-sizing: border-box;
  margin-bottom: 100px;
  position: relative;
}
@media only screen and (max-width: 900px) {
  .items li a {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 800px) {
  .items li a {
    margin-bottom: 60px;
  }
}
@media only screen and (max-width: 700px) {
  .items li a {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 700px) and (orientation: landscape) {
  .items li a {
    margin-bottom: 50px;
  }
}
.items li a .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.7);
  opacity: 0;
  transition: opacity .3s;
}
.items li a .overlay h3 {
  font-size: .9em;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 0;
  transition: transform .3s;
  position: relative;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 10%);
  background-color: black;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.items li a:hover .overlay {
  opacity: 1;
}
.items li a:hover .overlay h3 {
  transform: translate(-50%, -50%);
}
.items li a img {
  display: block;
  width: 100%;
}
.narrow,
.container {
  width: 800px;
}
@media only screen and (max-width: 900px) {
  .narrow,
  .container {
    width: 700px;
  }
}
@media only screen and (max-width: 800px) {
  .narrow,
  .container {
    width: 600px;
  }
}
@media only screen and (max-width: 700px) {
  .narrow,
  .container {
    width: 320px;
  }
}
@media only screen and (max-width: 700px) and (orientation: landscape) {
  .narrow,
  .container {
    width: 450px;
  }
}
.narrow {
  margin: 50px auto 0;
}
.container {
  margin: auto;
}
.message {
  text-align: center;
  margin-top: 7em;
}
.message h1,
.message h3 {
  letter-spacing: -1px;
}
.message h1 {
  color: #444;
  margin: 1em 0.5em 0;
  text-transform: uppercase;
}
.message h3 {
  color: #999999;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
}
.button {
  -webkit-font-smoothing: antialiased;
  padding: 10px 20px;
  border: 2px solid #444;
  color: #444;
  background-color: #444;
  color: #ffffff;
  border-radius: 25px;
  font-weight: 100;
}
.button:hover {
  background-color: #6c9bae;
  color: #ffffff;
  border-color: #6c9bae;
}
footer {
  background-color: #000000;
  color: #ffffff;
}
footer .footer-message {
  padding: 30px 0;
}
footer .footer-message h2,
footer .footer-message p {
  text-align: center;
}
footer .footer-message h2 {
  text-transform: uppercase;
}
footer .footer-message p {
  line-height: 1.2;
  font-size: .9em;
  margin: .5em 0;
  -webkit-font-smoothing: antialiased;
  color: #999999;
}
footer .vagebond-logo {
  opacity: .4;
}
footer .vagebond-logo h1 {
  margin: 0 0 1em 0;
  width: 130px;
  height: 50px;
  background: url(../../images/logo-vagebond.svg) 50% 50% no-repeat;
  background-size: contain;
}
footer .vagebond-logo h1 span {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  font-family: inherit;
  padding: 15px 20px 15px 0;
  display: block;
  width: 100%;
  border: none;
  background: none;
  color: #ffffff;
  border-bottom: 2px solid #444;
  border-radius: 3px;
  -webkit-appearance: none;
  margin: 10px 0;
  transition: border-color 0.3s;
  line-height: 1.5;
  font-size: 1em;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  outline: 0;
  outline: thin dotted \9;
  /* IE6-9 */
  outline: none;
  border-color: #6c9bae;
}
.form-group {
  position: relative;
}
.form-group label {
  position: absolute;
  left: 0;
  top: 47%;
  transform: translateY(-50%);
  color: #999999;
}
.form-group.textarea label {
  top: 21%;
}
.form-group input,
.form-group textarea {
  padding-left: 110px;
  box-sizing: border-box;
  resize: none;
}
form {
  margin: 5em 0 0;
}
form button {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1em;
  font-family: inherit;
  letter-spacing: 1px;
  margin: 2em 0;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s, background-color 0.3s;
  letter-spacing: 2px;
}
.form-output {
  font-size: .7em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #999999;
}
@media only screen and (min-width: 900px) {
  .form-output {
    display: inline-block;
    margin-left: 10px;
  }
}
.form-output span {
  color: #ffffff;
  margin-left: 10px;
}
button:focus {
  outline: 0;
}
/* Change the white to any color ;) */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px black inset;
  -webkit-text-fill-color: #ffffff !important;
}
