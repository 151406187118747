.narrow, .container {
    width: @width-content;

    @media @mq-medium {
        width: @width-content-medium;
    }

    @media @mq-tablet {
        width: @width-content-tablet;
    }

    @media @mq-phone {
        width: @width-content-phone;
    }

    @media @mq-phone-landscape {
        width: @width-content-phone-landscape;
    }
}

.narrow {
    margin: 50px auto 0;
}

.container {
    margin: auto;
}