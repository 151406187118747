textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"]{

	font-family: inherit;
	padding: 15px 20px 15px 0;
	display: block;
	width: 100%;
	border: none;
	background: none;
	color: @color-white;
    border-bottom: 2px solid @color-black;
	border-radius: 3px;
	-webkit-appearance: none;
    margin: 10px 0;
    transition: border-color .3s;
    line-height: 1.5;
    font-size: 1em;

	&:focus {
		outline: 0;
		outline: thin dotted \9; /* IE6-9 */
		outline: none;
        border-color: @color-blue;
	}
}

.form-group {
    position: relative;

    label {
        position: absolute;
        left: 0;
        top: 47%;
        transform: translateY(-50%);
        color: @color-gray;
    }

    &.textarea {
        label {
            top: 21%;
        }
    }

    input,
    textarea {
        padding-left: 110px;
        box-sizing: border-box;
        resize: none;
    }
}

form{
    margin: 5em 0 0;

    button {
        text-transform: uppercase;
        font-weight: normal;
        font-size: 1em;
        font-family: inherit;
        letter-spacing: 1px;
        margin: 2em 0;
        cursor: pointer;
        transition: border-color .3s, color .3s, background-color .3s;
        letter-spacing: 2px;
    }
}

.form-output {
    font-size: .7em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: @color-gray;

    @media @mq-large {
        display: inline-block;
        margin-left: 10px;
    }

    span {
        color: @color-white;
        margin-left: 10px;
    }
}

button:focus {
    outline: 0;
}

/* Change the white to any color ;) */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px black inset;
    -webkit-text-fill-color: @color-white !important;
}